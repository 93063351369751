<template>
  <div :class="tagClass" class="bitts-tag">
    <tag :class="colorClassName">
      <slot name="pre" />
      <slot>{{ text }}</slot>
      <slot name="post" />
    </tag>
  </div>
</template>

<script setup lang="ts">
import { Tag } from 'ant-design-vue';
import { computed } from 'vue';

import { BittsSize } from '../types';

const props = withDefaults(defineProps<Props>(), {
  color: 'neutral',
  text: '',
  size: 'small',
  variant: 'regular',
});

const MAP_COLOR_TO_CLASS: Record<string, string> = {
  accent: 'text-accent-text bg-accent-background-weak',
  neutral: 'bg-neutral-background text-neutral-text',
  'neutral-light':
    'bg-gradient-to-b from-neutral-background to-neutral-background-disabled text-neutral-text',
  'neutral-gradient':
    'bg-gradient-to-r from-neutral-background to-neutral-background/20 text-neutral-text',
  info: 'bg-info-background-weak text-info-text',
  'info-gradient':
    'bg-gradient-to-r from-info-background-weak to-info-background-weak/20 text-info-text',
  danger: 'bg-danger-background-weak text-danger-text',
  success: 'bg-success-background-weak text-success-text',
  'success-gradient':
    'bg-gradient-to-r from-success-background-weak to-success-background-weak/20 text-success-text',
  warning: 'bg-warning-background-weak text-warning-text',
  beta: 'bg-gradient-to-r from-beta-border to-beta-text text-white',
  upsell:
    'bg-gradient-to-r text-upsell-text-strong from-upsell-background-weak to-upsell-background-weak/20',
  'ecosystem-presence': 'bg-beta-background-weak text-beta-text',
  'early-adopter':
    'text-white bg-gradient-to-r from-info-border to-info-text leading-5',
  partner:
    'rounded-4 bg-gradient-to-r from-neutral-100 to-white text-neutral-text-weak',
  new: 'rounded-8 bg-gradient-to-r from-success-background to-green-700 text-white',
  white: 'bg-white',
};

interface Props {
  color?: string;
  text?: string;
  size?: Exclude<BittsSize, 'tiny' | 'medium' | 'large' | 'x-large'>;
  variant?: 'regular' | 'rounded' | 'category' | 'leaf';
}

const colorClassName = computed<string>(
  () => MAP_COLOR_TO_CLASS[props.color] || props.color,
);
const tagClass = computed(() => {
  let baseClass =
    props.variant && props.variant !== 'regular'
      ? `bitts-tag__${props.variant}`
      : '';
  baseClass += ` ${props.size}`;
  return baseClass;
});
</script>

<style lang="pcss">
.bitts-tag {
  .ant-tag {
    @apply text-base border-0 leading-6 px-8 m-0;
    font-family: inherit;
  }

  .ant-tag-hidden {
    @apply block;
  }

  &.x-small .ant-tag {
    @apply text-sm;
  }

  &.x-small.bitts-tag__rounded .ant-tag {
    @apply text-sm py-2;
  }

  &.bitts-tag__category .ant-tag {
    @apply text-xs rounded-bts-lg tracking-wider uppercase mr-4;
  }

  &.bitts-tag__rounded .ant-tag {
    @apply leading-4 mr-4 px-10 py-4;
    @apply rounded-bts-lg !important;
  }

  &.bitts-tag__leaf .ant-tag {
    @apply rounded-bts-md rounded-tl-none rounded-br-none px-8 py-0;
  }
  /* turn off animations for all tags we don't use this component for any click functionality */
  [ant-click-animating-without-extra-node='true']::after,
  .ant-click-animating-node {
    -webkit-animation: none;
    animation: none;
  }
}
</style>
